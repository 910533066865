import { ACTION_HOME_PAGE } from "../pages/homePage/constant/Constant";
const initialState = {
  page_route_details: [],
  tour_title: "",
  tour_amount: "",
  category: "",
  tour_type: "",
  visa_status: "",
  distination: [],
  suplement: "",
  tour_dates: [],
  currency: "",
  key_highlights: [],
  itenerary: [],
  inclusion: [],
  exclusion: [],
  accommodation: [],
  duration: [],
  tour_image: [],
  selected_data: [],
  filtered_data: [],
  main_data: [],
  local_data: [],
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_HOME_PAGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
