import { SELECTED_TOUR_ACTION } from "../pages/homePage/pageComponents/selectedTourPage/constant/Constant";
const initialState = {
  main_data: [],
  homepageData: [],
  route_info: [],
  filtered_data: "",
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_TOUR_ACTION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
